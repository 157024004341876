import { IconBase, IconBaseProps } from "react-icons";

export const IconLogo = (props: IconBaseProps) => {
  return (
    <IconBase
      size="1.5rem"
      viewBox="0 0 332 305"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99527 10.39C7.99527 4.86717 12.4724 0.390015 17.9953 0.390015H311.1C316.623 0.390015 321.1 4.86717 321.1 10.39V43.926C321.1 49.4489 316.623 53.926 311.1 53.926H17.9953C12.4724 53.926 7.99527 49.4489 7.99527 43.926V10.39Z"
        fill="currentColor"
      />
      <path
        d="M6.143 294.73C1.35762 292.4 -0.764449 286.73 1.31531 281.831L88.3347 76.8437C88.8051 75.7356 89.8924 75.016 91.0962 75.016L126.472 75.016C128.614 75.016 130.066 77.1966 129.24 79.1731L37.6328 298.303C35.4161 303.606 29.1966 305.953 24.0293 303.438L6.143 294.73Z"
        fill="currentColor"
      />
      <path
        d="M326.382 294.73C331.167 292.4 333.289 286.73 331.209 281.831L244.19 76.8437C243.72 75.7356 242.632 75.016 241.428 75.016L206.053 75.016C203.91 75.016 202.458 77.1966 203.285 79.1731L294.892 298.303C297.109 303.606 303.328 305.953 308.495 303.438L326.382 294.73Z"
        fill="currentColor"
      />
      <path
        d="M225.842 192.759C225.722 192.445 225.525 192.166 225.269 191.948L204.488 174.264C203.768 173.652 202.718 173.627 201.97 174.204L187.419 185.424C186.405 186.206 186.376 187.725 187.36 188.545L235.537 228.682C237.119 230 239.42 228.357 238.686 226.433L225.842 192.759Z"
        fill="currentColor"
      />
      <path
        d="M91.7927 227.024C91.0263 228.928 93.2832 230.613 94.8912 229.337L203.17 143.423C203.886 142.855 204.126 141.872 203.751 141.037L194.838 121.202C194.312 120.032 192.838 119.652 191.812 120.422L165.318 140.32C164.581 140.874 163.56 140.852 162.848 140.266L138.912 120.601C137.886 119.758 136.339 120.13 135.809 121.347L127.197 141.115C126.849 141.914 127.058 142.846 127.714 143.419L141.914 155.834C142.848 156.651 142.821 158.112 141.858 158.893L108.642 185.833C108.379 186.047 108.174 186.325 108.047 186.64L91.7927 227.024Z"
        fill="currentColor"
      />
    </IconBase>
  );
};
